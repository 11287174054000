import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Colors } from '../../../theme/Colors';
import { Images } from '../../../theme/Images';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import StyledText from '../../atoms/StyledText';

const BannerTopHome: React.FC = () => {
    return (
        <BannerWrapper className='banner-wrapper'>
            <BannerHolder className='banner-holder'>
                <ContainerB fluid className='container-b'>
                    <Container>
                        <Row className='align-items-center'>
                            <Col lg='8'>
                                <TextWrapper>
                                    <StyledSubtitle
                                        color={Colors.primaryColor}
                                        fontSize='32px'
                                        fontWeight='bold'
                                    >
                                        En los pequeños detalles...
                                    </StyledSubtitle>
                                    <StyledText>
                                        está la diferencia, en grabado GPM fabricamos de una pieza personalizada hasta diseños de linea en acero inoxidable, tumbaga y latón, todas las piezas son elaboradas con un proceso 100% a mano.
                                    </StyledText>
                                </TextWrapper>
                            </Col>
                            <Col lg='4'>
                                <ImgSpotifyWrapper>
                                    <ImgSpotify className='img-fluid' src={Images.dijesSpotify} />
                                </ImgSpotifyWrapper>
                            </Col>
                        </Row>
                    </Container>
                </ContainerB>
            </BannerHolder>
        </BannerWrapper>
    )
}

const ContainerB = styled(Container)`
    background: ${Colors.thirdColor};
    padding-top: 32px;
`;

const BannerWrapper = styled.div`
    margin-bottom: 80px;
`;

const BannerHolder = styled.div`
`;

const TextWrapper = styled.div``;

const ImgSpotifyWrapper = styled.div``;

const ImgSpotify = styled.img``;

export default BannerTopHome
