import React from 'react';
import styled from 'styled-components';
import Loading from '../../atoms/Loading';
import Carousel from 'react-carousel-elasticss';
import './carousel.css';
import useGetImages from './hooks/useGetImages';

interface CaourselProps {
    idProduct: string;
};

const ImagesCarousel: React.FC<CaourselProps> = ({idProduct}) => {
    
    const {loading, images} = useGetImages(idProduct);

    return (
        <>
            <CarouselWrapper id='carousel-product' className='carousel-wrapper'>
                <CarouselHolder className='carousel-holder'>
                    <Carousel
                        isRTL={false}
                        pagination={false}
                    >
                        {
                            images.map( (image)=> <ImageCarousel className='img-fluid' key={image.name} src={image.url} alt={image.name} /> )
                        }
                    </Carousel>
                </CarouselHolder>
            </CarouselWrapper>
            <Loading visible={loading} />
        </>
    )
}

const CarouselWrapper = styled.div`
    margin-bottom: 40px;
`;


const CarouselHolder = styled.div`
    position: relative;
`;

const ImageCarousel = styled.img`
    border-radius: 20px;
`;

export default ImagesCarousel
