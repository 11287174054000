import { useJsApiLoader } from '@react-google-maps/api';

interface CenterProps {
    lat: number;
    lng: number;
};

const useMap = () : {isLoaded: boolean, center: CenterProps} => {

    const { isLoaded } = useJsApiLoader({id: 'google-map-script', googleMapsApiKey: "AIzaSyBlshAaDTkiFBm0_S3KZZcF5eaYVjFQ9oU"});

    const center = {
        lat: 19.384054,
        lng: -99.144193
    };


    return {
        isLoaded,
        center
    }
}

export default useMap
