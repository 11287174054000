import styled from 'styled-components';
import { screenSizes } from '../../fixtures/screenSizes';
import { Colors } from '../../theme/Colors';

interface props {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    style?: {};
    children: React.ReactNode;
};

const StyledText: React.FC<props> = ({
    color, 
    fontSize, 
    fontWeight, 
    marginTop, 
    marginRight,
    marginBottom,
    marginLeft,
    children, 
    style}) => {
    return (
        <Text
            style={style}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            marginTop={marginTop}
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
        >
            {children}
        </Text>
    )
}

const Text = styled.p<props>`
    color: ${props => props.color || Colors.primaryColor};
    font-size: ${props => props.fontSize || '18px'};
    font-weight: ${props => props.fontWeight || 'normal'};
    margin-top: ${props => props.marginTop || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};

    @media(max-width: ${screenSizes.md}){
        font-size: 11px;
    }
`;


export default StyledText
