import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Colors } from '../../../theme/Colors';
import { Images } from '../../../theme/Images';
import InputText from '../../atoms/InputText';
import Loading from '../../atoms/Loading';
import MainButton from '../../atoms/MainButton';
import StyledText from '../../atoms/StyledText';
import useNewsLetter from './hooks/useNewsLetter';

const NewsLetter: React.FC = () => {

    const {loading, email, handleEmail, addNewsletter} = useNewsLetter();

    return (
        <>
            <NewsletterWrapper>
                <NewsletterHolder>
                    <ContainerB>
                        <Row className='align-items-center'>
                            <Col md='3'>
                                <img className='img-fluid' src={Images.dijePersonaje} />
                            </Col>
                            <Col md='6'>
                                <StyledText
                                    fontSize='32px'
                                    fontWeight='bold'
                                    style={{textAlign: 'center'}}
                                >
                                    Suscríbete ahora
                                </StyledText>
                                <StyledText
                                    style={{textAlign: 'center'}}
                                    marginBottom='40px'
                                >
                                    Podrás recibir ofertas especiales y precios preferenciales.
                                </StyledText>
                                <InputNewsWrapper className='d-flex align-items-top'>
                                    <InputText 
                                        placeholder='Correo electrónico'
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                    <MainButton
                                        onClick={addNewsletter}
                                    >
                                        Suscribirse
                                    </MainButton>
                                </InputNewsWrapper>
                            </Col>
                            <Col md='3'>
                                <img className='img-fluid' src={Images.dijeZodiaco} />
                            </Col>
                        </Row>
                    </ContainerB>
                </NewsletterHolder>
            </NewsletterWrapper>
            <Loading visible={loading} />
        </>
    )
}

const NewsletterWrapper = styled.div``;

const NewsletterHolder = styled.div``;

const ContainerB = styled(Container)`
    background: ${Colors.fourthColor};
`;

const InputNewsWrapper = styled.div`
    margin-bottom: 8px;
`;

export default NewsLetter
