import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../redux/store';
import Loading from '../../atoms/Loading';
import ProductItem from '../../molecules/ProductItem/ProductItem';
import useProducts from './hooks/useProducts';

const ProductList: React.FC = () => {

    const {loading} = useProducts();
    const {visibleProducts} = useSelector((state: RootState) => state.appState);

    return (
        <>
            <ProductListWrapper className='product-list-wrapper'>
                <ProductListHolder className='product-list-holder'>
                    <Container>
                        <Row>
                            {
                                visibleProducts.map((product)=>{
                                    return(
                                        <Col key={product.id} sm='6' md='4' lg='3'>
                                            <ProductItem
                                                id={product.id}
                                                name={product.name}
                                                main_image={product.main_image}
                                                categories={product.categories}
                                                price={product.price}
                                                enabled={product.enabled}
                                                created_at={product.created_at}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </ProductListHolder>
            </ProductListWrapper>
            <Loading visible={loading} />
        </>
    )
}

const ProductListWrapper = styled.div``;

const ProductListHolder = styled.div``;

export default ProductList
