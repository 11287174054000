import React from 'react';
import styled from 'styled-components';
import {Images} from '../../theme/Images';

interface LogoProps {
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    width?: string;
    height?: string;
    logoType?: string;
};

const GaiLogo: React.FC<LogoProps> = ({
    marginTop, 
    marginRight, 
    marginBottom, 
    marginLeft,
    width,
    height,
    logoType,
}) => {
    return (
        <ImgLogo 
            className='img-fluid' 
            src={Images.gaiLogo}
            marginTop={marginTop}
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            width={width}
            height={height}
        />
    )
}

const ImgLogo = styled.img<LogoProps>`
    margin-top: ${props => props.marginTop};
    margin-right: ${props => props.marginRight};
    margin-bottom: ${props => props.marginBottom};
    margin-left: ${props => props.marginLeft};
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'auto'};
`;

export default GaiLogo
