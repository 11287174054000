import { useEffect, useState } from "react";
import { storage } from "../../../../fixtures/firebase";
import { getDownloadURL, list, ref } from "@firebase/storage";

interface Images {
    url: string;
    name: string;
};

const useGetImages = (idProduct: string): {loading: boolean, images: Images[]} => {

    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState<Images[]>([]);

    useEffect(()=>{
        getImages();
    },[idProduct]);

    const getImages = async()=> {
        setLoading(true);
        const images_founded: Images[] = [];
        const refImages = ref(storage, `/products/${idProduct}/images`);
        const images = await list(refImages);
        await Promise.all(
            images.items.map(async(image)=>{
                let image_item: Images = {
                    url: '',
                    name: ''
                };
                const image_url = await getDownloadURL(image);
                image_item.url = image_url;
                image_item.name = image.name.split('.')[0];
                images_founded.push(image_item);
            })
        );
        setImages(images_founded);
        setLoading(false);
    }

    return {
        loading,
        images
    }
}

export default useGetImages
