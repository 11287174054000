import { addDoc, collection, Timestamp } from '@firebase/firestore';
import React, { useState } from 'react';
import { firestore } from '../../../../fixtures/firebase';
import swal from 'sweetalert';

const useNewsLetter = (): {
    loading: boolean;
    email: string;
    handleEmail: (e: any)=>void,
    addNewsletter: ()=>void
} => {

    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
 
    const handleEmail = (e: any)=> {
        const email = e.target.value;
        setEmail(email);
    }

    const addNewsletter = async()=> {
        setLoading(true);
        const add_news_letter = await addDoc(collection(firestore,'newsletter'),{
            email,
            created_at: Timestamp.now()
        });
        setLoading(false);
        if(add_news_letter.id){
            swal({
                text: 'Tu correo electrónico ha sido guardado correctamente, ¡espera nuestras promociones!',
                icon: 'success'
            });
            setEmail("");
        }else{
            swal({
                text: 'Tu correo electrónico no ha sido guardado, inténtalo más tarde.',
                icon: 'warning'
            });
        }
    }


    return {
        loading,
        email,
        handleEmail,
        addNewsletter,
    }
}

export default useNewsLetter
