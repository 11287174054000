import { Timestamp } from "@firebase/firestore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Product {
    id: string;
    name: string;
    categories: string[];
    main_image: string;
    price: number;
    enabled: boolean;
    created_at: Timestamp;
};

interface AppState {
    categorySelected: string;
    products: Product[],
    visibleProducts: Product[],
};

const initialState: AppState = {
    categorySelected: 'Brazaletes',
    products: [],
    visibleProducts: [],
};

export const appSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setCategorySelected: (state, action: PayloadAction<string>)=> {
            state.categorySelected = action.payload;
        },
        setProducts: (state, action: PayloadAction<Product[]>)=> {
            state.products = action.payload;
        },
        setVisibleProducts: (state, action: PayloadAction<Product[]>)=> {
            state.visibleProducts = action.payload
        }
    }
});

export const {setCategorySelected, setProducts, setVisibleProducts} = appSlice.actions;
export default appSlice.reducer;