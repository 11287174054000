import moment from 'moment';
import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy: React.FC = () => {
    return (
        <Container>			
            <p><strong>AVISO DE PRIVACIDAD</strong></p>
            <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, <strong><span id="span_id_persona_que_recaba_los_datos">Grabado GPM</span></strong> pone a su disposición el siguiente aviso de privacidad.</p>
            <p><strong><span id="span_id_persona_que_recaba_los_datos">Grabado GPM</span></strong>, es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.</p>
            <p>Además de lo anterior, informamos a usted que <strong><span id="span_id_persona_que_recaba_los_datos">Grabado GPM</span></strong>, tiene su domicilio ubicado en:</p>
            <p><span id="span_id_domicilio_del_responsable">Av. Gabriel Ramos Millán 105, colonia Niños Héroes de Chapultepec, C.P. 03440, Benito Juárez, Ciudad de México</span></p>
            <p>Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como para atender los servicios y/o pedidos que solicite:</p>
            <p><span id="span_id_finalidad_de_uso_de_datos">Diseño y grabado personalizado de artículos / piezas en acero inoxidable, así como el envío a domicilio de los mismos.</span></p>
            <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
            <p><span id="span_id_datos_personales_que_se_recaban">Nombre completo, Teléfonos, Dirección completa, correo electrónico, Cookies (Web, App Mobile)</span></p>
            <p>Por otra parte, informamos a usted, que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distintas a nosotros y serán utilizados exclusivamente para los fines señalados.</p>
            <p>Usted tiene en todo momento el derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como también a oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
            <p>Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico:</p>
            <p><span id="span_id_caso_correo">ventas@grabadogpm.com</span></p>
            <p>Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, no obstante, la solicitud de ejercicio de estos derechos debe contener la siguiente información:</p>
            <p><span id="span_id_opcional_info_de_solicitud_derechos_arco">Nombre completo, Teléfono, dirección completa</span></p>
            <p>La respuesta a la solicitud se dará en el siguiente plazo: <span id="span_id_tiempo_de_respuesta_derechos_arco">3 días hábiles</span>, y se comunicará de la siguiente manera:</p>
            <p><span id="span_id_opcional_medio_de_respuesta_derechos_arco">A través del correo electrónico del cual se genere la solictud.</span></p>
            <p>Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>
            <p></p>
            <p>a) Nombre del responsable: <span id="span_id_persona_fisica_responsable_arco">Martin Guevara Pérez</span></p>
            <p>b) Domicilio:</p>
            <p><span id="span_id_dom_persona_fisica_responsable_arco">Av. Gabriel Ramos Milán 105, colonia Niños Héroes de Chapultepec, C.P. 03444, Benito Juárez, Ciudad de México</span></p>

            <p>d) Correo electrónico: <span id="span_id_opcional_mail_persona_fisica_responsable_arco">ventas@grabadogpm.com</span></p>

            <p>e) Otro medio de contacto: <span id="span_id_opcional_otro_medio_de_contacto_persona_fisica_responsable_arco">https://grabadogpm.com</span>.</p>

            <p>Cabe mencionar, que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales. Del mismo modo, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Asimismo, usted deberá considerar que para ciertos fines la revocación de su consentimiento implicará que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>
            <p>Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico:</p>
            <p><span id="span_id_caso_correo_revocacion">ventas@grabadogpm.com</span></p>
            <p>Del mismo modo, podrá solicitar la información para conocer el procedimiento y requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal, sin embargo, estas solicitudes deberán contener la siguiente información:</p>
            <p><span id="span_id_opcional_info_de_solicitud_revocacion">Nombre completo, teléfono y dirección</span></p>
            <p>La respuesta a la solicitud de revocación o limitación de divulgación de sus datos se dará a más tardar en el siguiente plazo: <span id="span_id_tiempo_de_respuesta_revocacion">3 días habiles</span>, y se comunicará de la siguiente forma:</p>
            <p><span id="span_id_opcional_medio_de_respuesta_revocacion">A través del correo electrónico del cual se genere la solictud.</span></p>
            <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, sin embargo, usted puede solicitar información sobre si el mismo ha sufrido algún cambio a través de la siguiente dirección electrónica:</p>
            <p><span id="span_id_caso_web_cambioap">https://grabadogpm.com</span></p>

            <p>Última actualización:</p>
            <p><span id="span_id_fecha_de_actualizacion">{moment().format('DD-MM-YYYY')}</span></p>
		</Container>
    )
}

export default PrivacyPolicy
