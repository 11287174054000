export const config = {
    firebaseConfig: {
        apiKey: "AIzaSyAGAoec0yggNfAuuAdxnATdjKNhjO9boDI",
        authDomain: "grabadosenaceroweb.firebaseapp.com",
        projectId: "grabadosenaceroweb",
        storageBucket: "grabadosenaceroweb.appspot.com",
        messagingSenderId: "1012519892538",
        appId: "1:1012519892538:web:149656bde969a71f18a703",
        measurementId: "G-ZQ5RQMVWXJ"
        // apiKey: "AIzaSyDA6OLlv0JGocWZlMj5VAG_aOJvxul2jc4",
        // authDomain: "grabadosenaceroinoxidable.firebaseapp.com",
        // projectId: "grabadosenaceroinoxidable",
        // storageBucket: "grabadosenaceroinoxidable.appspot.com",
        // messagingSenderId: "958526151048",
        // appId: "1:958526151048:web:98b12e3293f949afe8823d",
        // measurementId: "G-3J0YCBCXDX"
    }
};