import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import BannerTopHome from '../design/molecules/BannerTopHome/BannerTopHome';
import ListCategories from '../design/molecules/ListCategories/ListCategories';
import NewsLetter from '../design/organism/Newsletter/NewsLetter';
import ProductList from '../design/organism/ProductList/ProductList';

const Home: React.FC = () => {
    return (
        <HomeWrapper className='home-wrapper'>
            <HomeHolder className='home-holder'>
                <BannerTopHome />
                <CategoriesHolder className='categories-holder'>
                    <Container>
                        <Row>
                            <Col>
                                <ListCategories />
                            </Col>
                        </Row>
                    </Container>
                </CategoriesHolder>
                <ProductList />
                <NewsLetter />
            </HomeHolder>
        </HomeWrapper>
    )
}

const HomeWrapper = styled.div``;

const HomeHolder = styled.div``;

const CategoriesHolder = styled.div`
    margin-bottom: 36px;
`;

export default Home
