import React from 'react';
import styled from 'styled-components'
import { Colors } from '../../theme/Colors';
import StyledText from './StyledText';
import {IoLogoWhatsapp} from 'react-icons/io5';
import { screenSizes } from '../../fixtures/screenSizes';
import { logEvent } from '@firebase/analytics';
import { analytics } from '../../fixtures/firebase';
import { Product } from '../../redux/slices/appSlice';

interface WhatsAppButtonCotizacionProps {
    product: Product
};

const WhatsAppButtonCotizacion: React.FC<WhatsAppButtonCotizacionProps> = ({product}) => {

    const GotoWhatsApp = ()=> {
        logEvent(analytics, 'press_btn_wa_product', product);
        window.open('https://wa.me/525652323154', '_blank');
    }

    return (
        <WhatsAppButtonCotizacionWrapper onClick={GotoWhatsApp}>
            <IoLogoWhatsapp color={Colors.white} size={32} />
            <TextButton>
                ¡Solicita tu cotización ahora!
            </TextButton>
        </WhatsAppButtonCotizacionWrapper>
    )
}

const WhatsAppButtonCotizacionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 320px;
    height: 50px;
    background: ${Colors.greenWhatsApp};
    border-radius: 40px;

    @media(max-width: ${screenSizes.md}){
        width: 60px;
        height: 60px;
    }
`;

const TextButton = styled.span`
    color: ${Colors.white};
    font-size: 18px;
    font-weight: bold;
    margin-right: 8px;

    @media(max-width: ${screenSizes.md}){
        display: none;
    }
`;

export default WhatsAppButtonCotizacion
