import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
//import 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
//import 'firebase/storage';
import {getStorage, ref} from 'firebase/storage';
import { config } from './config';

const firebaseApp = initializeApp(config.firebaseConfig);

export const analytics = getAnalytics(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = ref(getStorage(firebaseApp));