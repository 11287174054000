import React from 'react'
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';

interface InputTextAreaProps {
    placeholder: string;
    value: string;
    onChange: (e: any)=>void
}; 

const InputTextArea: React.FC<InputTextAreaProps> = ({placeholder, value, onChange}) => {
    return (
        <TextAreaInput 
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    )
}

const TextAreaInput = styled.textarea`
    resize: none;
    height: 200px;
    padding-left: 16px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px ${Colors.secondaryColor};
`;

export default InputTextArea
