import React from 'react';
import styled from 'styled-components'
import { Colors } from '../../theme/Colors';
import {IoLogoWhatsapp} from 'react-icons/io5';
import { screenSizes } from '../../fixtures/screenSizes';
import { logEvent } from '@firebase/analytics';
import { analytics } from '../../fixtures/firebase';

const WhatsAppButton: React.FC = () => {

    const GotoWhatsApp = ()=> {
        logEvent(analytics, 'press_btn_wa_general');
        window.open('https://wa.me/525652323154', '_blank');
    }

    return (
        <WhatsAppButtonWrapper onClick={GotoWhatsApp}>
            <TextButton>
                ¿Cómo podemos ayudarte?
            </TextButton>
            <IoLogoWhatsapp color={Colors.white} size={32} />
        </WhatsAppButtonWrapper>
    )
}

const WhatsAppButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 320px;
    height: 50px;
    background: ${Colors.greenWhatsApp};
    border-radius: 40px;
    position: fixed;
    z-index: 10000;
    bottom: 4%;
    right: 5%;

    @media(max-width: ${screenSizes.md}){
        width: 60px;
        height: 60px;
    }
`;

const TextButton = styled.span`
    color: ${Colors.white};
    font-size: 18px;
    font-weight: bold;
    margin-right: 8px;

    @media(max-width: ${screenSizes.md}){
        display: none;
    }
`;

export default WhatsAppButton
