import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../theme/Colors';

const Divider = () => {
    return (
        <DividerLine></DividerLine>
    )
}

const DividerLine = styled.div`
    height: 1px;
    background: ${Colors.secondaryColor};
`;

export default Divider
