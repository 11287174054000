import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../../../theme/Colors';
import GaiLogo from '../../atoms/GaiLogo';
import StyledTitle from '../../atoms/StyledTitle';

const TopMenu: React.FC = () => {
    return (
        <TopMenuWrapper className='top-menu-wrapper'>
            {/* <Container>
                <GaiLogo marginTop='8px' marginBottom='8px' width='70px' height='70px' />
            </Container> */}
            <TopMenuHolder className='top-menu-holder'>
                <Nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container">
                        <LogoWrapper className='d-flex'>
                            <a className="navbar-brand d-lg-flex align-items-center" href="/">
                                <GaiLogo marginRight='16px' logoType='acero' width='150' height='150' />    
                                <StyledTitle 
                                    color={Colors.white}
                                    fontSize='20px'
                                    fontWeight='bolder'
                                >
                                    Grabado GPM
                                </StyledTitle>
                            </a>
                        </LogoWrapper>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse flex-grow-0" id="navbarNavAltMarkup">
                            <Menu className='menu navbar-nav me-auto mb-2 mb-lg-0'>
                                <LinkScreen exact activeStyle={{color: Colors.white, fontWeight: 'bold'}} to='/'>Productos</LinkScreen>
                                <LinkScreen exact activeStyle={{color: Colors.white, fontWeight: 'bold'}} to='/contacto'>Contáctanos</LinkScreen>
                            </Menu>
                        </div>
                    </div>
                </Nav>
            </TopMenuHolder>
        </TopMenuWrapper>
    )
}

const TopMenuWrapper = styled.div`

`;

const TopMenuHolder = styled.div`
    padding-bottom: 32px;
`;

const Nav = styled.nav`
    padding: 24px 0px;
`;

const LogoWrapper = styled.div`
`;

const Menu = styled.ul`
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0px;
`;

const LinkScreen = styled(NavLink)`
    padding: 16px;
    color: ${Colors.secondaryColor};
    text-decoration: none;

    :hover{
        color: ${Colors.white};
    }
`;

export default TopMenu
