import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../theme/Colors';

interface MainButtonProps {
    onClick: ()=>void
    children: React.ReactNode;
};

const MainButton: React.FC<MainButtonProps> = ({children, onClick}) => {
    return (
        <Button
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

const Button = styled.button`
    border: 0px;
    background: ${Colors.primaryColor};
    color: ${Colors.white};
    height: 50px;
`;

export default MainButton
