import styled from 'styled-components';
import { screenSizes } from '../../fixtures/screenSizes';
import { Colors } from '../../theme/Colors';

interface props {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    children: React.ReactNode;
};

const StyledSubtitle: React.FC<props> = ({color, fontSize, fontWeight, children}) => {
    return (
        <Text
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
        >
            {children}
        </Text>
    )
}

const Text = styled.h2<props>`
    color: ${props => props.color || Colors.secondaryColor};
    font-size: ${props => props.fontSize || '18px'};
    font-weight: ${props => props.fontWeight || 'normal'};

    @media (max-width: ${screenSizes.sm}){
        font-size: 18px;
    }
`;


export default StyledSubtitle
