import React from 'react';
import styled from 'styled-components'
import { Colors } from '../../theme/Colors';

interface InputTextProps {
    placeholder: string;
    value: string;
    onChange: (e: any)=>void
};

const InputText: React.FC<InputTextProps> = ({placeholder, value, onChange}) => {
    return (
        <InputTextWrapper className='flex-grow-1 input-text-wrapper'>
            <InputTextHolder className='input-text-holder'>
                <Input type='text' placeholder={placeholder} value={value} onChange={onChange} />
            </InputTextHolder>
        </InputTextWrapper>
    )
}
const InputTextWrapper = styled.div`
    margin-bottom: 16px;
`;

const InputTextHolder = styled.div``;

const Input = styled.input`
    background: ${Colors.white};
    border: solid 1px ${Colors.secondaryColor};
    height: 50px;
    width: 100%;
    padding-left: 16px;
    border-radius: 8px;

    :focus{
        outline: none;
    }
`;

export default InputText
