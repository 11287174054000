import { useEffect, useState } from 'react';
import { collection, where, query, onSnapshot } from '@firebase/firestore';
import { firestore } from '../../../../fixtures/firebase';
import { Product, setProducts, setVisibleProducts } from '../../../../redux/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

const useProducts = (): {loading: boolean} => {

    const [loading, setLoading] = useState(true);
    const {categorySelected} = useSelector((state: RootState) => state.appState);
    const dispatch = useDispatch();

    useEffect(()=>{
        const q = query(collection(firestore,'products'), where('enabled','==',true));
        const unsubscribe = onSnapshot(q,(querySnapshot)=>{
            const products_founded: Product[] = [];
            querySnapshot.forEach((product)=>{
                const data_product: any = product.data();
                data_product.id = product.id;
                products_founded.push(data_product);
            });
            dispatch(setProducts(products_founded));
            let visible_products = [...products_founded];
            visible_products = visible_products.filter(p => p.categories.includes(categorySelected));
            dispatch(setVisibleProducts(visible_products));
            setLoading(false);
        })
        return ()=> {
            unsubscribe();
        }
    },[]);
    
    return {
        loading
    }

}

export default useProducts
