import React, { useState } from 'react'
import { Toast } from 'react-bootstrap'
import { Colors } from '../../../theme/Colors'
import StyledText from '../../atoms/StyledText'

const LaserToast = () => {

    const [show, setShow] = useState(true);

    return (
        <Toast show={show} bg='warning' autohide={true} delay={25000} onClose={()=>{setShow(false)}}>
            <Toast.Header />
            <Toast.Body>
                <StyledText
                    color={Colors.white}
                >
                    NO SE TRABAJA GRABADO LASER, este es un FOTOGRABADO, un trabajo de mayor calidad que requiere un tiempo de proceso, por lo que los tiempos de entrega varian según el diseño deseado.
                </StyledText>
            </Toast.Body>
        </Toast>
    )
}

export default LaserToast
