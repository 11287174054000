import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import useMap from "./hooks/useMap";
import StyledTitle from "../../atoms/StyledTitle";

const Map: React.FC = () => {
  const { isLoaded, center } = useMap();

  return (
    <>
      <StyledTitle fontSize="24px" marginBottom="16px">
        ¿Dónde nos encontramos?
      </StyledTitle>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "600px",
          }}
          center={center}
          zoom={17}
        >
          <Marker
            position={center}
          />
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
