import { useState } from "react";
import { collection, addDoc } from "@firebase/firestore";
import { firestore } from "../../../../fixtures/firebase";
import swal from 'sweetalert';


const useContact = (): {
    loading: boolean; 
    name: string; 
    celular: string; 
    email: string;
    message: string;
    handleName: (e: any)=>void,
    handleCelular: (e: any)=>void,
    handleEmail: (e: any)=>void,
    handleMessage: (e: any)=>void,
    sendEmail: ()=>void
} => {

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [celular, setCelular] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const cleanForm = ()=> {
        setName('');
        setCelular('');
        setEmail('');
        setMessage('');
    }

    const handleName = (e: any)=> {
        const name = e.target.value;
        setName(name);
    }

    const handleCelular = (e: any)=> {
        const celular = e.target.value;
        setCelular(celular);
    }

    const handleEmail = (e: any)=> {
        const email = e.target.value;
        setEmail(email);
    }

    const handleMessage = (e: any)=> {
        const message = e.target.value;
        setMessage(message);
    }

    const sendEmail = async()=>{
        setLoading(true);
        const text_email = `
            Nombre: ${name}
            Celular: ${celular}
            Email: ${email},
            Mensaje: ${message}
        `;
        const contact_form_web = await addDoc(collection(firestore,'contactFormWeb'),{
            to: 'ventas@grabadogpm.com',
            bcc: 'jagowm@gmail.com',
            replyTo: `${name} <${email}>`,
            message: {
                subject: 'Nuevo correo desde Grabado GPM',
                text: text_email,
            }
        });
        setLoading(false);
        if(contact_form_web.id){
            swal({
                text: 'La información ha sido enviada correctamente, te responderemos loa más pronto posible.',
                icon: 'success'
            });
            cleanForm();
        }else{
            swal({
                text: 'La información no ha podido ser enviada, inténtalo más tarde..',
                icon: 'warning'
            });
        }
    }

    return {
        loading,
        name,
        celular,
        email,
        message,
        handleName,
        handleCelular,
        handleEmail,
        handleMessage,
        sendEmail,
    }
}

export default useContact
