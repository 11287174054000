import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Carousel from 'react-carousel-elasticss';
import './carousel.css';
import { RootState } from '../../../redux/store';
import { Product } from '../../../redux/slices/appSlice';
import ProductItem from '../../molecules/ProductItem/ProductItem';
import StyledText from '../../atoms/StyledText';
import Divider from '../../atoms/Divider';
import { screenSizes } from '../../../fixtures/screenSizes';

interface RelatedProductsProps {
    idProduct: string
};

const RelatedProductCarousel: React.FC<RelatedProductsProps> = ({idProduct}) => {

    const {visibleProducts} = useSelector((state: RootState) => state.appState);
    const [products, setProducts] = useState<Product[]>([]);
    const breakpoints = [
        { width: 1, itemsToShow: 1},
        { width: 576, itemsToShow: 2},
        { width: 768, itemsToShow: 3},
        { width: 992, itemsToShow: 4},
    ];
    
    useEffect(() => {
        let visible_products = [...visibleProducts];
        visible_products = visibleProducts.filter(p => p.id !==  idProduct);
        setProducts(visible_products);
    }, [idProduct]);

    return (
        <RelatedProductsWrapper className='related-products-wrapper'>
            <Divider />
            <StyledText
                fontSize='20px'
                style={{textAlign:'center'}}
                marginBottom='16px'
                marginTop='16px'
                fontWeight='bold'
            >
                Productos Relacionados
            </StyledText>
            <RelatedProductsHolder className='related-products-holder'>
                <Carousel 
                        isRTL={false}
                        pagination={false}
                        itemPadding={[8,8,8,8]}
                        breakPoints={breakpoints}
                    >
                        {
                            products.map((product)=>{
                                return(
                                    <ProductItem 
                                        id={product.id}
                                        main_image={product.main_image}
                                        categories={product.categories}
                                        name={product.name}
                                        price={product.price}
                                        enabled={product.enabled}
                                        created_at={product.created_at}
                                    />
                                )
                            })
                        }
                </Carousel>
            </RelatedProductsHolder>
        </RelatedProductsWrapper>
    )
}

const RelatedProductsWrapper = styled.div``;

const RelatedProductsHolder = styled.div``;

export default RelatedProductCarousel
