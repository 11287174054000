import React from 'react';
import TopMenu from './design/organism/TopMenu/TopMenu';
import {Switch, Route} from 'react-router-dom';
import Home from './screens/Home';
import Contact from './screens/Contact';
import Footer from './design/organism/Footer/Footer';
import DetailProduct from './screens/DetailProduct';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Terms from './screens/Terms';
import WhatsAppButton from './design/atoms/WhatsAppButton';
import CopyRightImagesToast from './design/molecules/Toasts/CopyRightImagesToast';
import LaserToast from './design/molecules/Toasts/LaserToast';
import ToastContainer  from 'react-bootstrap/ToastContainer';

const App: React.FC = () => {
  return (
    <>
      <TopMenu />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/product/:name_product' component={DetailProduct} />
        <Route exact path='/contacto' component={Contact} />
        <Route exact path='/terminos_condiciones' component={Terms} />
        <Route exact path='/aviso_privacidad' component={PrivacyPolicy} />
      </Switch>
      <Footer />
      <WhatsAppButton />
      <ToastContainer style={{position: "absolute"}} position="top-end">
        <CopyRightImagesToast />
        <LaserToast />
      </ToastContainer>
    </>
  )
}

export default App
