import styled from 'styled-components'
import { Colors } from '../../theme/Colors';

const CategoryLabel = styled.li`
    color: ${Colors.primaryColor};
    font-size: 14px;
    font-weight: lighter;
    background: ${Colors.white};
    padding: 3px 6px;
`;

export default CategoryLabel
