import dijesSpotify from '../images/banner_spotify.png';
import gaiLogo from '../images/gai_logo.png';
import gaiLogoBlack from '../images/gai_logo_black.png';
import gaiLogoLight from '../images/gai_logo_light.png';
import dijeZodiaco from '../images/dije_zodiaco.png';
import dijePersonaje from '../images/dije_personaje.png';

export const Images = {
    gaiLogo,
    gaiLogoBlack,
    gaiLogoLight,
    dijesSpotify,
    dijeZodiaco,
    dijePersonaje
};