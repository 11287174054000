import { useEffect, useState } from "react";
import { collection, query, where, onSnapshot, orderBy } from "@firebase/firestore";
import { firestore } from "../../../../fixtures/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setCategorySelected, setVisibleProducts } from "../../../../redux/slices/appSlice";
import { RootState } from "../../../../redux/store";

interface Category {
    id: string;
    name: string;
    enabled: boolean;
};

const useCategories = (): {
    categories: Category[],    
    handleCategorySelected: (e: any)=> void
} => {
    
    const [categories, setCategories] = useState<Category[]>([]);
    const {products} = useSelector((state: RootState)=> state.appState);
    const dispatch = useDispatch();

    useEffect(() => {
        const q = query(collection(firestore, 'categories'), where('enabled','==',true), orderBy('name','asc'));
        const unsubscribe = onSnapshot(q, (querySnapshot)=>{
            const categories_founded: any[] = [];
            querySnapshot.forEach((category)=>{
                const data_category = category.data();
                data_category.id = category.id;
                categories_founded.push(data_category);
            });
            setCategories(categories_founded);
        });
        return ()=> {
            unsubscribe();
        }
    }, []);
 
    const handleCategorySelected = (e: any)=> {
        const category = e.target.innerText;
        dispatch(setCategorySelected(category));
        let visible_products = [...products];
        visible_products = visible_products.filter(p => p.categories.includes(category));
        dispatch(setVisibleProducts(visible_products));
    }

    return {
        categories,
        handleCategorySelected
    }
}

export default useCategories
