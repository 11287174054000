import React from 'react';
import styled from 'styled-components';
import InputText from '../../atoms/InputText';
import InputTextArea from '../../atoms/InputTextArea';
import Loading from '../../atoms/Loading';
import MainButton from '../../atoms/MainButton';
import StyledTitle from '../../atoms/StyledTitle';
import useContact from './hooks/useContact';

const ContactForm: React.FC = () => {

    const {
        loading, 
        name, 
        celular, 
        email,
        message,
        handleName,
        handleCelular,
        handleEmail,
        handleMessage,
        sendEmail
    } = useContact();

    return (
        <>
            <FormWrapper>
                <StyledTitle
                    fontSize='24px'
                    marginBottom='16px'
                >
                        ¿Cómo podemos ayudarte?
                </StyledTitle>
                <FormHolder className='form-holder'>
                    <InputText 
                        placeholder='Nombre completo' 
                        value={name}
                        onChange={handleName}
                    />
                    <InputText 
                        placeholder='Celular' 
                        value={celular}
                        onChange={handleCelular}
                    />
                    <InputText 
                        placeholder='Correo electrónico' 
                        value={email}
                        onChange={handleEmail}
                    />
                    <InputTextArea 
                        placeholder='Escribe tu mensaje...' 
                        value={message}
                        onChange={handleMessage}
                    />
                </FormHolder>
                <div className='d-flex justify-content-end'>
                    <MainButton
                        onClick={sendEmail}
                    >
                        Enviar
                    </MainButton>
                </div>
            </FormWrapper>
            <Loading visible={loading} />
        </>
    )
}

const FormWrapper = styled.div``;

const FormHolder = styled.div``;

export default ContactForm
