import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { Circles } from 'react-loader-spinner';

interface LoadingProps {
    visible: boolean;
};

const Loading: React.FC<LoadingProps> = ({visible}) => {

    return (
        <>
            {
                visible &&
                <LoadingWrapper>
                    <LoadingHolder>
                        <Circles
                            width={150}
                            height={150} 
                            color={Colors.primaryColor}
                        />
                    </LoadingHolder>
                </LoadingWrapper>
            }
        </>
    )
}

const LoadingWrapper = styled.div`
    background-color: rgba(255,255,255,0.80);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    display:flex;
    justify-content: center;
    align-items: center;
`;

const LoadingHolder = styled.div``;

export default Loading