import React, { useState } from 'react'
import { Toast } from 'react-bootstrap'
import { Colors } from '../../../theme/Colors'
import StyledText from '../../atoms/StyledText'

const CopyRightImagesToast = () => {

    const [show, setShow] = useState(true);

    return (
        <Toast show={show} bg='success' autohide={true} delay={15000} onClose={()=>{setShow(false)}}>
            <Toast.Header />
            <Toast.Body>
                <StyledText
                    color={Colors.white}
                >
                    Todas las imágenes, logos y marcas comerciales mostradas en este sitio web son propiedad de sus creadores.
                </StyledText>
            </Toast.Body>
        </Toast>
    )
}

export default CopyRightImagesToast
