import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Map from '../design/molecules/Map/Map';
import ContactForm from '../design/organism/ContactForm/ContactForm';

const Contact: React.FC = () => {

    return (
        <ContactWrapper className='contact-wrapper'>
            <ContactHolder className='contact-holder'>
                <Container>
                    <Row className='mb-5'>
                        <Col>
                            <ContactForm />
                        </Col>
                    </Row>
                    <Row>
                        <Map />
                    </Row>
                </Container>
            </ContactHolder>
        </ContactWrapper>
    )
}

const ContactWrapper = styled.div``;

const ContactHolder = styled.div``;

export default Contact
