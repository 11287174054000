import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { screenSizes } from '../../../fixtures/screenSizes';
import { RootState } from '../../../redux/store';
import { Colors } from '../../../theme/Colors';
import useCategories from './hooks/useCategories';

interface ListItemProps {
    active: boolean;
    onClick: (e: any)=> void
};

const ListCategories: React.FC = () => {

    const {categorySelected} = useSelector((state: RootState) => state.appState);
    const {categories, handleCategorySelected} = useCategories();

    return (
        <ListCategoriesWrapper className='list-categories-wrapper'>
            <ListCategoriesHolder className='list-categories-holder'>
                <List>
                    {categories.map((category) =>{
                        return(
                            <ListItem
                                key={category.id} 
                                active={categorySelected === category.name}
                                onClick={handleCategorySelected}
                            >
                                {category.name}
                            </ListItem>
                        )
                    })}
                </List>
            </ListCategoriesHolder>
        </ListCategoriesWrapper>
    )
}

const ListCategoriesWrapper = styled.div``;

const ListCategoriesHolder = styled.div``;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ListItem = styled.li<ListItemProps>`
    display: inline-block;
    padding-right: 16px;
    color: ${props => props.active ? Colors.primaryColor : Colors.disabledTextColor};
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;

    :hover{
        color: ${Colors.primaryColor};
    }

    @media(max-width: ${screenSizes.sm}){
        display: block;
        font-size: 18px;
    }
`;

export default ListCategories
