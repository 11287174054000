import moment from 'moment';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components'
import { Colors } from '../../../theme/Colors';
import GaiLogo from '../../atoms/GaiLogo';
import StyledText from '../../atoms/StyledText';
import {IoLocationOutline, IoMailOutline, IoCallOutline} from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { screenSizes } from '../../../fixtures/screenSizes';

const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <ContainerB fluid>
                <FooterHolder>
                    <Container>
                        <Row>
                            <Col lg='6' className='mb-4'>
                                <LogoWrapper className='d-flex align-items-center'>
                                    {/* <GaiLogo width='70px' height='70px' marginRight='8px' /> */}
                                    <StyledText
                                        fontSize='24px'
                                        color={Colors.primaryColor}
                                    >
                                        Grabado GPM
                                    </StyledText>
                                </LogoWrapper>
                                <DataWrapper className='d-flex align-items-center'>
                                    <IoLocationOutline 
                                        color={Colors.primaryColor} 
                                        size={20}
                                        style={{marginRight: '8px'}}
                                    />
                                    <StyledText
                                        fontSize='14px'
                                    >
                                        <AnchorTel 
                                            href='https://www.google.com.mx/maps/place/Grabados+en+Acero+Inoxidable/@19.3837281,-99.146395,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1fe553d37c53b:0x61a06581793020f8!8m2!3d19.383736!4d-99.1442049'
                                            target='_blank'
                                        >
                                            Ramos Millán 105, Niños Héroes de Chapultepec, 03440, Benito Juárez, CDMX
                                        </AnchorTel>
                                    </StyledText>
                                </DataWrapper>
                                <DataWrapper className='d-flex align-items-center'>
                                    <IoMailOutline 
                                        color={Colors.primaryColor} 
                                        size={18}
                                        style={{marginRight: '8px'}} 
                                    />
                                    <StyledText
                                        fontSize='14px'
                                    >
                                        ventas@grabadogpm.com
                                    </StyledText>
                                </DataWrapper>
                            </Col>
                            <Col lg='6'>
                                <StyledText
                                    fontSize='24px'
                                >
                                    Información
                                </StyledText>
                                <FooterMenu>
                                    <LinkScreen to='/contacto'>Contáctanos</LinkScreen>
                                    <LinkScreen to='/terminos_condiciones'>Términos y Condiciones</LinkScreen>
                                    <LinkScreen to='/aviso_privacidad'>Aviso de Privacidad</LinkScreen>
                                </FooterMenu>
                            </Col>
                        </Row>
                    </Container>
                </FooterHolder>
            </ContainerB>
            <ContainerBB fluid>
                <FooterHolder>
                    <Container>
                        <Row>
                            <Col>
                                <StyledText
                                    style={{textAlign: 'center'}}
                                    fontSize='12px'
                                >
                                    {`Copyright © ${moment().format('YYYY')} Grabado GPM todos los derechos reservados`}
                                </StyledText>
                            </Col>
                        </Row>
                    </Container>
                </FooterHolder>
            </ContainerBB>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.div`
    padding: 80px 0px 0px;
`;

const FooterHolder = styled.div`
    padding: 24px 0px;
`;

const ContainerB = styled(Container)`
    background: ${Colors.thirdColor};
`;

const ContainerBB = styled(Container)`
    background: ${Colors.fourthColor};
`;

const LogoWrapper = styled.div`
    margin-bottom: 16px;
`;

const DataWrapper = styled.div`
    margin-bottom: 8px;
`;

const FooterMenu = styled.ul`
    margin: 0px;
    padding: 0px;
    list-style: none;
`;

const LinkScreen = styled(Link)`
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: block;
    color: ${Colors.primaryColor};
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 8px;

    :hover {
        color: inherit;
    }

    :visited {
        color: inherit;
    }

    :active {
        color: inherit;
    }
`;

const AnchorTel = styled.a`
    color: ${Colors.primaryColor};
    text-decoration: none;

    :hover {
        color: inherit;
        text-decoration: inherit;
    }

    :visited {
        color: inherit;
        text-decoration: inherit;
    }

    :active {
        color: inherit;
        text-decoration: inherit;
    }
`;

export default Footer
