import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
import styled from 'styled-components'
import Divider from '../design/atoms/Divider';
import StyledText from '../design/atoms/StyledText';
import StyledTitle from '../design/atoms/StyledTitle';
import WhatsAppButtonCotizacion from '../design/atoms/WhatsAppButtonCotizacion';
import ImagesCarousel from '../design/molecules/ImagesCarousel/ImagesCarousel';
import RelatedProductCarousel from '../design/organism/RelatedProductCarousel/RelatedProductCarousel';
import { FormatPrice } from '../fixtures/helpers';
import { Product } from '../redux/slices/appSlice';
import { Colors } from '../theme/Colors';

const DetailProduct: React.FC = () => {

    const {state} = useLocation<{product: Product}>();
    const {product} = state;

    return (
        <DetailProductWrapper className='detail-product-wrapper'>
            <DetailProductHolder className='detail-product-holder'>
                <MainDetail>
                    <Container>
                        <Row className='align-items-center'>
                            <Col lg='6'>
                                <ImagesCarousel idProduct={product.id} />
                            </Col>
                            <Col lg='6'>
                                {/* <StyledTitle
                                    fontSize='32px'
                                    color={Colors.primaryColor}
                                    marginBottom='36px'
                                    fontWeight='bold'
                                >
                                    {product.productName}
                                </StyledTitle>
                                <DataProductHolder>
                                    <StyledText
                                        fontSize='18px'
                                        fontWeight='bold'
                                    >
                                        Precio:
                                    </StyledText>
                                    <StyledText
                                        fontSize='16px'
                                        fontWeight='bold'
                                        color={Colors.secondaryColor}
                                    >
                                        {FormatPrice(product.price)}
                                    </StyledText>
                                </DataProductHolder>
                                <Divider />
                                <DataProductHolder>
                                    <StyledText
                                        fontSize='18px'
                                        fontWeight='bold'
                                    >
                                        Categorias:
                                    </StyledText>
                                    {
                                        product.categories.map((category)=>{
                                            return(
                                                <StyledText
                                                    fontSize='18px'
                                                    fontWeight='bold'
                                                    color={Colors.secondaryColor}
                                                >   
                                                    {category}
                                                </StyledText>
                                            )
                                        })
                                    }
                                </DataProductHolder>
                                <Divider />
                                <DataProductHolder>
                                    <StyledText
                                        fontSize='18px'
                                        fontWeight='bold'
                                    >
                                        Tiempo de entrega:
                                    </StyledText>
                                    <StyledText
                                        fontSize='18px'
                                        fontWeight='bold'
                                        color={Colors.secondaryColor}
                                    >   
                                        24 Hrs.
                                    </StyledText>
                                </DataProductHolder>
                                <Divider /> */}
                                <WhatsAppButtonCotizacion product={product} />
                            </Col>
                        </Row>
                    </Container>
                </MainDetail>
                <Related>
                    <Container>
                        <RelatedProductCarousel idProduct={product.id} />
                    </Container>
                </Related>
            </DetailProductHolder>
        </DetailProductWrapper>
    )
}

const DetailProductWrapper = styled.div``;

const DetailProductHolder = styled.div``;

const MainDetail = styled.div`
    margin-bottom: 45px;
`;

const DataProductHolder = styled.div`
    margin: 16px 0px;
`;

const Related = styled.div``;

export default DetailProduct
