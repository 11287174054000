import React from 'react';
import styled from 'styled-components';
import { FormatPrice } from '../../../fixtures/helpers';
import { Colors } from '../../../theme/Colors';
import CategoryLabel from '../../atoms/CategoryLabel';
import {gsap} from 'gsap';
import { useHistory } from 'react-router';
import { logEvent } from '@firebase/analytics';
import { analytics } from '../../../fixtures/firebase';
import { Product } from '../../../redux/slices/appSlice';

const ProductItem: React.FC<Product> = ({id, main_image, categories, name, price, enabled, created_at}) => {

    const history = useHistory();

    const zoomImage = (id: string)=> {
        gsap.to(`.product-${id}`, {scale: 1.3})
    }
    
    const zoomOutImage = (id: string)=> {
        gsap.to(`.product-${id}`, {scale: 1})
    }
    
    const GoDetailProduct = ()=> {
        const product: Product = {
            id, 
            main_image,
            categories,
            name,
            price,
            enabled,
            created_at
        };
        logEvent(analytics, 'select_product', product);
        history.push(`/product/${product.name}`, {product});
    }

    return (
        <ProductItemWrapper 
            onMouseEnter={()=>{zoomImage(id)}} 
            onMouseLeave={()=>{zoomOutImage(id)}} 
            className='product-item-wrapper'
            onClick={GoDetailProduct}
        >
            <ProductItemHolder className='product-item-holder'>
                <ImageWrapper className='image-wrapper'>
                    <ImageHolder>
                        <Image className={`img-fluid product-${id}`} src={main_image} />
                    </ImageHolder>
                    <LabelWrapper className='label-wrapper'>
                        <LabelList className='label-list'>
                            {
                                categories.map((category)=>{
                                    return(
                                        <CategoryLabel key={category}>{category}</CategoryLabel>
                                    )
                                })
                            }
                        </LabelList>
                    </LabelWrapper>
                </ImageWrapper>
                <ProductTitle>{name}</ProductTitle>
                {/* <ProductPrice>{FormatPrice(price)}</ProductPrice> */}
            </ProductItemHolder>
        </ProductItemWrapper>
    )
}

const ProductItemWrapper = styled.div`
    margin-bottom: 48px;
    cursor: pointer;
`;

const ProductItemHolder = styled.div``;

const ImageWrapper = styled.div`
    position: relative;
    border-bottom: solid 3px ${Colors.primaryColor};
    margin-bottom: 24px;
`;

const ImageHolder = styled.div`
    overflow: hidden;
`;

const Image = styled.img``;

const LabelWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 8px;
`;

const LabelList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ProductTitle = styled.h2`
    color: ${Colors.primaryColor};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    min-height: 44px;
`;

const ProductPrice = styled.span`
    color: ${Colors.secondaryColor};
    font-size: 14px;
    font-weight: bold;
`;


export default ProductItem
