import { logEvent } from "@firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        document.title = `Grabado GPM ${pathname === '/' ? '' : pathname.replace('/','')}`;
         setTimeout(()=>{
            window.scrollTo(0, 0);
        },500);
    }, [pathname]);

    return null;
}

export default ScrollToTop
